import Image from "next/image";
import Link from "next/link";
import React from "react";
import Meta from "../components/Meta";

const Error_page = () => {
  return (
    <div>
			<Meta title="404 | CIRSYS Tech - Desarrollamos tecnología para el beneficio social." desc={"En CIRSYS buscamos crear tecnología para un mundo más sostenible. Somos una empresa que aporta valor a los negocios desarrollando proyectos con Inteligencia Artificial, Robotica Social, Automatización, desarrollo de Hardware y Software."} keyword={"Inteligencia Artificial, Robotica Social, Automatización, desarrollo de Hardware y Software, proyectos, irbin, cirsys, cirsys tech, tecnología, medio ambiente"} />
      <div className="pt-[5.5rem] dark:bg-jacarta-1100 bg-jacarta-1100">
        {/* <!-- 404 --> */}
        <section className="dark:bg-jacarta-1100 bg-jacarta-1100 relative py-16 md:py-24">
          <div className="container">
            <div className="mx-auto text-center">
            <h1 className="font-display mb-6 text-6xl dark:text-white text-white">
                404
              </h1>
              <h1 className="font-display mb-6 text-4xl dark:text-white text-white md:text-6xl">
               Página no encontrada!
              </h1>
              <p className="dark:text-jacarta-300 text-jacarta-300 mb-12 text-lg leading-normal">
                Parece que la página que buscas no existe.
              </p>
              <Link href="/">
                <a className="bg-accent hover:bg-accent-dark w-40 rounded-full py-3 px-8 text-center font-semibold text-black transition-all ease-out duration-200">
                  VOLVER A HOME
                </a>
              </Link>
            </div>
          </div>
        </section>
        {/* <!-- end 404 --> */}
      </div>
    </div>
  );
};

export default Error_page;
